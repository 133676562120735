import React from 'react';
import styled from 'styled-components';
import Faq from '../components/faq/Faq';
import bird2 from '../assets/images/birds/bird2.png';
import Button from '../components/button';

const Page = styled.div`
  margin-top: 7.5vw;
  header {
    h1 {
      font-size: 3em;
      padding-bottom: 30px;
    }
  }
  .siteWrapper {
    grid-column: 1 / span 24;
    @media (min-width: 768px) {
      grid-column: 5 / span 16;
    }
    .intro-body {
      margin-bottom: 30px;
      .sub-title {
        font-size: 1.2rem;
      }
    }
  }
`;

const Bird = styled.div`
  position: absolute;
  left: -70px;
  top: 100px;

  z-index: 0;
  width: 40vw;
  max-width: 400px;
  height: auto;
  opacity: 0.7;
  transform: translateY(-50%) rotate(0deg);
`;

export default function FaqPage() {
  return (
    <Page>
      <Bird>
        <img src={bird2} alt="" />
      </Bird>
      <div className="SiteContainer">
        <div className="siteWrapper">
          <header>
            <h1>Faq & Safety</h1>
          </header>
          <div className="intro-body">
            <p className="b">
              For the 1st edition of Magnetic Fields Nomads we are only offering
              a limited number of residential packages. It will not be possible
              to attend the event unless you are staying on site with
              accommodation booked through us. We have made this decision in
              order to create as safe and controlled environment as possible.
            </p>
            <p>
              {' '}
              <span className="b-i sub-title">
                Creating a safe space during COVID
              </span>
              <br />
              <br />
              We have worked hard to create a set of guidelines to ensure
              everyone’s safety.
            </p>
            <p>
              A RT-PCR test is mandatory to attend within 48 hours prior to
              checking in. This has to be done from a certified test centre.
              There is no compromise with this rule, if you fail to provide a
              negative test we will not admit you into the event, your tickets
              and package will not be refunded.
            </p>
            <p className="b-i">
              Please make sure you read all the following information carefully.
              Those attending can download a list of approved nationwide labs
              below.
              <br />
              <br />
              <Button
                linkto="https://www.icmr.gov.in/pdf/covid/labs/COVID_Testing_Labs_05032021.pdf"
                linktext="List of Approved Labs"
              />
            </p>
            <div className="rust">
              <p className="b ">
                Disclaimer: Whilst all these labs promise delivery within 24hrs,
                it’s very much the responsibility of the attendee to check that
                they’ll get results in time of the event. You will not be let on
                to site without a valid RT-PCR test from within 48 hours of
                check-in. If you have already had a Covid-19 vaccination, you
                will still need to get tested prior to attending.
                <br />
                <br />
                You must carry a print out of this, which we will keep for our
                records. There are no exceptions to this rule.
              </p>
              <p className="b ">
                We’re programming this event in a way that utilises
                predominantly outdoor areas with a lot of space. The event is a
                residential gathering and single/day tickets are not going to be
                available.
              </p>
              <p className="b ">
                The extensive venue will safely accommodate all attendees and we
                have carefully programmed the event using spaces that allow for
                a substantial distance for each experience.
              </p>
              <p className="sb ">
                All staff, volunteers, vendors, performers and musicians will be
                tested, no exceptions. Over the course of the weekend, we
                encourage you to not leave the site during your stay. Face
                coverings will be necessary when in close proximity to anyone
                not in your immediate bubble. No drinks or food will be served
                to anyone not wearing a face covering.
              </p>
            </div>
            <p className="sb-i">Summary of Standards</p>
            <ul>
              <li>
                <p>
                  Rooms will be sanitised prior to your arrival and communal
                  areas will be sanitised every few hours.
                </p>
              </li>
              <li>
                <p>
                  All touch points in public areas like door handles, counter
                  tops, table tops and railings, will be cleaned continuously
                  using a sanitiser/disinfectant. These practices are also in
                  place in guest rooms during the morning housekeeping service.
                </p>
              </li>
              <li>
                <p>
                  Masks and disposable gloves will be worn by our team members
                  at all times and changed frequently. Housekeeping staff use
                  fresh gloves for every room they service.
                </p>
              </li>
              <li>
                <p>
                  Sprayer with professionally identified chemicals are being
                  used to disinfect the venue entrances, employee entrances, as
                  well as various public areas.
                </p>
              </li>
              <li>
                <p>
                  For the safety of our guests, rooms will be sanitised after
                  guests check out, kept vacant for 48 hours and then allocated
                </p>
              </li>
              <li>
                <p>
                  Protocols are in place for staff in the kitchens, restaurants,
                  and bars to sanitise their hands every time they serve food or
                  touch food related items.
                </p>
              </li>
              <li>
                <p>
                  Temperature readings of all guests and staff will be taken
                  regularly across site and at key locations.
                </p>
              </li>
              <li>
                <p>All luggage shall be sanitised before entering the hotel.</p>
              </li>
            </ul>
          </div>
          <h1>FAQ</h1>
          <br />
          <Faq />
        </div>
      </div>
    </Page>
  );
}
